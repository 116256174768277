import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	noClassification: {
		id: 'data-classification-level.no-classification',
		defaultMessage: 'No classification',
		description: 'Text for the no classification option',
	},
	classifyContent: {
		id: 'data-classification-level.edit-popup.classify-content',
		defaultMessage: 'Classify content',
		description: 'Text for the header of the edit popup',
	},
	selectClassification: {
		id: 'data-classification-level.edit-popup.select-classification',
		defaultMessage: 'Select classification',
		description: 'Text for the label of the select dropdown to select a classification',
	},
	updateButton: {
		id: 'data-classification-level.edit-popup.update-button',
		defaultMessage: 'Update',
		description: 'Text for the update button',
	},
	cancelButton: {
		id: 'data-classification-level.edit-popup.cancel-button',
		defaultMessage: 'Cancel',
		description: 'Text for the cancel button',
	},
	changeLevel: {
		id: 'data-classification-level.read-popup.change-level',
		defaultMessage: 'Change level',
		description: 'Text for the button that changes the level of the selected classification',
	},
	rules: {
		id: 'data-classification-level.rules',
		defaultMessage: '({associatedPoliciesCount, plural, one {# rule} other {# rules}})',
		description:
			'Text next to the title of the classification describing the number of rules associated',
	},
});
