import React from 'react';

import { styled } from '@compiled/react';

import { Box } from '@atlaskit/primitives';
import { ReactRenderer } from '@atlaskit/renderer';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';

import { guidelineMaxLength, nameMaxLength } from '../../constants';
import { type GuidelineRepresentation } from '../../types';

type ClassificationViewProps = {
	name?: string;
	guideline?: string;
	guidelineRepresentation?: GuidelineRepresentation;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Name = styled.h2({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.heading.medium', fontFallback.heading.medium),
	color: token('color.text', '#172B4D'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Guideline = styled.p({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
	color: token('color.text', '#172B4D'),
	whiteSpace: 'pre-wrap',
	overflowWrap: 'break-word',
});

export const ClassificationView = ({
	name,
	guideline,
	guidelineRepresentation = 'PLAIN',
}: ClassificationViewProps) => {
	const nameTruncated = (name ?? '').substring(0, nameMaxLength);
	const guidelineTruncated = (guideline ?? '').substring(0, guidelineMaxLength);

	return (
		<Box>
			{nameTruncated && <Name id="data-classification-read-content-name">{nameTruncated}</Name>}
			{guideline && (
				<Box paddingBlockStart="space.200">
					{guidelineRepresentation === 'PLAIN' ? (
						<Guideline id="data-classification-read-content-guideline">
							{guidelineTruncated}
						</Guideline>
					) : (
						<ReactRenderer maxHeight={200} truncated={true} document={JSON.parse(guideline)} />
					)}
				</Box>
			)}
		</Box>
	);
};
