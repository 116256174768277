import { createIntl } from 'react-intl-next';

import { messages } from '../messages';

import { type ClassificationOption, type Color } from './types';

const { formatMessage } = createIntl({ locale: 'en' });

export const NO_CLASSIFICATION_OPTION: ClassificationOption = {
	definition: formatMessage(messages.noClassification),
	includeIcon: false,
};

export const nameMaxLength = 50;
export const definitionMaxLength = 1000;
export const guidelineMaxLength = 1000;

export type ColorValues = {
	fontColor: string;
	bgColor: string;
	bgColorHovered: string;
	bgColorPressed: string;
	bgColorFocused?: string;
};

export type ColorsConfig = {
	light: Record<Color, ColorValues>;
	dark: Record<Color, ColorValues>;
};

type DisabledColorValues = Pick<ColorValues, 'fontColor' | 'bgColor'>;

export type DisabledColorConfig = {
	light: DisabledColorValues;
	dark: DisabledColorValues;
};

export const colorsConfig: ColorsConfig = {
	light: {
		YELLOW: {
			fontColor: '#693200',
			bgColor: '#FFF5D4',
			bgColorHovered: '#FFE48F',
			bgColorPressed: '#FFC716',
			bgColorFocused: '#FFF5D4',
		},
		ORANGE: {
			fontColor: '#3A2C1F',
			bgColor: '#FFC716',
			bgColorHovered: '#FCA700',
			bgColorPressed: '#FA8700',
			bgColorFocused: '#FCA700',
		},
		RED: {
			fontColor: '#AE2E24',
			bgColor: '#FFECEB',
			bgColorHovered: '#FFD5D2',
			bgColorPressed: '#FD9891',
			bgColorFocused: '#FFECEB',
		},
		RED_BOLD: {
			fontColor: '#FFFFFF',
			bgColor: '#C9372C',
			bgColorHovered: '#AE2E24',
			bgColorPressed: '#5D1F1A',
			bgColorFocused: '#C9372C',
		},
		PURPLE: {
			fontColor: '#352C63',
			bgColor: '#DFD8FD',
			bgColorHovered: '#B8ACF6',
			bgColorPressed: '#9F8FEF',
			bgColorFocused: '#DFD8FD',
		},
		BLUE: {
			fontColor: '#09326C',
			bgColor: '#CCE0FF',
			bgColorHovered: '#85B8FF',
			bgColorPressed: '#579DFF',
			bgColorFocused: '#CCE0FF',
		},

		TEAL: {
			fontColor: '#206A83',
			bgColor: '#E7F9FF',
			bgColorHovered: '#C6EDFB',
			bgColorPressed: '#9DD9EE',
			bgColorFocused: '#E7F9FF',
		},
		GREEN: {
			fontColor: '#164B35',
			bgColor: '#BAF3DB',
			bgColorHovered: '#7EE2B8',
			bgColorPressed: '#4BCE97',
			bgColorFocused: '#BAF3DB',
		},
		GREY: {
			fontColor: '#172B4D',
			bgColor: '#F1F2F4',
			bgColorHovered: '#DCDFE4',
			bgColorPressed: '#B3B9C4',
			bgColorFocused: '#F1F2F4',
		},
		LIME: {
			fontColor: '#4C6B1F',
			bgColor: '#EFFFD6',
			bgColorHovered: '#D3F1A7',
			bgColorPressed: '#B3DF72',
			bgColorFocused: '#EFFFD6',
		},
	},
	dark: {
		YELLOW: {
			fontColor: '#FFE48F',
			bgColor: '#3A2C1F',
			bgColorHovered: '#693200',
			bgColorPressed: '#9E4C00',
			bgColorFocused: '#3A2C1F',
		},
		ORANGE: {
			fontColor: '#3A2C1F',
			bgColor: '#FFC716',
			bgColorHovered: '#FCA700',
			bgColorPressed: '#FA8700',
			bgColorFocused: '#FCA700',
		},
		RED: {
			fontColor: '#FD9891',
			bgColor: '#42221F',
			bgColorHovered: '#5D1F1A',
			bgColorPressed: '#AE2E24',
			bgColorFocused: '#42221F',
		},
		RED_BOLD: {
			fontColor: '#1D2125',
			bgColor: '#F87168',
			bgColorHovered: '#FD9891',
			bgColorPressed: '#FFD5D2',
			bgColorFocused: '#F87168',
		},
		PURPLE: {
			fontColor: '#DFD8FD',
			bgColor: '#352C63',
			bgColorHovered: '#5E4DB2',
			bgColorPressed: '#6E5DC6',
			bgColorFocused: '#352C63',
		},
		BLUE: {
			fontColor: '#CCE0FF',
			bgColor: '#09326C',
			bgColorHovered: '#0055CC',
			bgColorPressed: '#0C66E4',
			bgColorFocused: '#09326C',
		},
		TEAL: {
			fontColor: '#9DD9EE',
			bgColor: '#1E3137',
			bgColorHovered: '#164555',
			bgColorPressed: '#206A83',
			bgColorFocused: '#1E3137',
		},
		GREEN: {
			fontColor: '#BAF3DB',
			bgColor: '#164B35',
			bgColorHovered: '#216E4E',
			bgColorPressed: '#1F845A',
			bgColorFocused: '#164B35',
		},
		GREY: {
			fontColor: '#B6C2CF',
			bgColor: '#2C333A',
			bgColorHovered: '#38414A',
			bgColorPressed: '#454F59',
			bgColorFocused: '#2C333A',
		},
		LIME: {
			fontColor: '#B3DF72',
			bgColor: '#28311B',
			bgColorHovered: '#37471F',
			bgColorPressed: '#4C6B1F',
			bgColorFocused: '#28311B',
		},
	},
};

// Doesn't exist within `colorsConfig` since it's not a valid `Color` type
export const disabledColorConfig: DisabledColorConfig = {
	light: {
		fontColor: '#091E42',
		bgColor: 'rgba(9, 30, 66, 0.03)',
	},
	dark: {
		fontColor: '#BFDBF847',
		bgColor: 'rgba(3, 4, 4, 0.26)',
	},
};
